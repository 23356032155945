import { Component } from "react";
import { CgInstagram } from "react-icons/cg";
import { AiFillTwitterSquare } from "react-icons/ai";
import { AiFillFacebook } from "react-icons/ai";
import "./style.css";

const Footer = () => {
  return (
    <footer id="contact" className="container footer__container">
      <div className="about">
        <h3>TRITA MEDS</h3>
        <p className="text">
          At Trita Meds, we are actively working to expand our product offerings
          under our own brand Our products are crafted to provide optimal
          comfort and support to patients during their recovery, ensuring
          efficient healing and ease of use both pre- and post-surgery
        </p>
        {/* <ul className="footer__socials">
          <li>
            <a href="#">
              <AiFillFacebook />
            </a>
          </li>
          <li>
            <a href="#">
              <CgInstagram />
            </a>
          </li>
          <li>
            <a href="#">
              <AiFillTwitterSquare />
            </a>
          </li>
        </ul> */}
      </div>
      <div className="services">
        <h3>Services</h3>
        <ul>
          <li>
            <a href="#" className="text">
              Drapes
            </a>
          </li>
          <li>
            <a href="#" className="text">
              Packs
            </a>
          </li>
          <li>
            <a href="#" className="text">
              Surgical Kits
            </a>
          </li>
        </ul>
      </div>
      <div className="contact">
        <h3>Contact</h3>
        <ul>
          <li>
            <address className="text">
              Office : Trita Meds 1st Floor, Naz Central Building Edamuttam -
              Valapad Thrissur – 680567 Kerala, India
            </address>
          </li>
          <li>
            <a href="tel:+917592050758" className="text">
              Tel : +917592050758
            </a>
          </li>
          <li>
            <a href="mailto:info@tritameds.com" className="text">
              Email: info@tritameds.com
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};
/*
class Footer extends Component {
  render() {
    return (
      <footer id="contact" className="container footer__container">
        <div className="about">
          <h3>LOGO</h3>
          <p className="text">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste saepe
            voluptatum repellat placeat aperiam. Molestiae consectetur molestias
            magni laudantium doloremque!
          </p>
          <ul className="footer__socials">
            <li>
              <a href="#">
                <AiFillFacebook />
              </a>
            </li>
            <li>
              <a href="#">
                <CgInstagram />
              </a>
            </li>
            <li>
              <a href="#">
                <AiFillTwitterSquare />
              </a>
            </li>
          </ul>
        </div>
        <div className="services">
          <h3>Services</h3>
          <ul>
            <li>
              <a href="#" className="text">
                Cyber Security
              </a>
            </li>
            <li>
              <a href="#" className="text">
                IT COnsultant
              </a>
            </li>
            <li>
              <a href="#" className="text">
                Strategy & Research
              </a>
            </li>
          </ul>
        </div>
        <div className="contact">
          <h3>Contact</h3>
          <ul>
            <li>
              <address className="text">
                Office : Valapad
              </address>
            </li>
            <li>
              <a href="tel:+917592050758" className="text">
                Tel : +917592050758
              </a>
            </li>
            <li>
              <a href="mailto:info@tritameds.com" className="text">
                Email: info@tritameds.com
              </a>
            </li>
          </ul>
        </div>
      </footer>
    );
  }
}
*/
export default Footer;
