// import { Component } from "react";

const Logo = () => {
  return (
    <div className="logo">
      <h3>TRITA MEDS</h3>
    </div>
  );
};
export default Logo;
/*
class Logo extends Component {
  render() {
    return (
      <div className="logo">
        <h3>LOGO</h3>
      </div>
    );
  }
}
export default Logo;
*/