import { Component, useState } from "react";
import { GrShieldSecurity } from "react-icons/gr";
import { BsHeart, BsLaptop } from "react-icons/bs";
import Button from "../Global__Components/Button";

const ServicesComp = () => {
  const [loadMore, setLoadMore] = useState(false);
  const [loadMoreText, setLoadMoreText] = useState("Load More");
  const toggleLoadMore = () => {
    setLoadMore(!loadMore);
    setLoadMoreText(loadMore ? "Load More" : "Load Less");
  };
  const loadLessThreshold = 2;
  const ServicesItem = (props) => {
    return (
      <div>
        <div className="icon">{props.icon}</div>
        <h4>{props.title}</h4>
        <p>{props.content}</p>
      </div>
    );
  };

  // servives Item list
  const servicesItems = [
    {
      icon: <GrShieldSecurity />,
      title: "Drapes",
      content: "We also offer customized drapes.",
    },
    {
      icon: <BsHeart />,
      title: "Packs",
      content: "We offer customized packs.",
    },
    {
      icon: <GrShieldSecurity />,
      title: "Authorized Distributor of Lister Medica",
      content:
        "We specialize in providing sterilized surgical drapes and packs from Lister Medica",
    },
    {
      icon: <BsLaptop />,
      title: "Exclusive Distributor of Dr.Matrix",
      content: "Trita Meds exclusively distributes the Dr.Matrix brand",
    },
  ];

  return (
    <div className="services__servicesComp">
      <div>
        <h1>Our Services For The Care You Provide</h1>
        <button className="btn btn-primary" onClick={toggleLoadMore}>
          {loadMoreText}
        </button>
      </div>
      <div>
        {servicesItems.map((item, index) => {
          if (!loadMore && index >= loadLessThreshold) {
            return null;
          }
          return (
            <ServicesItem
              key={index}
              icon={item.icon}
              title={item.title}
              content={item.content}
            />
          );
        })}
      </div>
    </div>
  );
};
/*
class ServicesComp extends Component {
  render() {
    return (
      <div className="services__servicesComp">
        <div>
          <h1>Our Servcices For Technology You Need</h1>
          <button className="btn btn-primary">Load More</button>
        </div>
        <div>
          <div>
            <div className="icon">
              <GrShieldSecurity />
            </div>
            <h4>Cyber Security</h4>
            <p>Lorem ipsum dolor sit amet consectetur.</p>
          </div>
          <div>
            <div className="icon">
              <BsLaptop />
            </div>
            <h4>IT Consultant</h4>
            <p>Lorem ipsum dolor sit amet consectetur.</p>
          </div>
          <div>
            <div className="icon">
              <GrShieldSecurity />
            </div>
            <h4>Cyber Security</h4>
            <p>Lorem ipsum dolor sit amet consectetur.</p>
          </div>
          <div>
            <div className="icon">
              <BsLaptop />
            </div>
            <h4>IT Consultant</h4>
            <p>Lorem ipsum dolor sit amet consectetur.</p>
          </div>
        </div>
      </div>
    );
  }
}
*/
export default ServicesComp;
