import { Component, useState } from "react";
import Work from "./Work_box";
import "./style.css";

const Portfolio = () => {
  const works = [
    {
      img: "https://images.pexels.com/photos/7108230/pexels-photo-7108230.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      title: "Authorized Distributor of Lister Medica",
      text: "We specialize in providing sterilized surgical drapes and packs from Lister Medica, a renowned leader in surgical products.  Our offerings include: Drapes and packs for orthopedic, cardiovascular, ENT, and general surgery. We also offer drapes and packs for Urology, Neurology and Gynecology",
    },
    {
      img: "https://images.pexels.com/photos/7108232/pexels-photo-7108232.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      title: "Exclusive Distributor of Dr.Matrix (by Abhinav Distributors)",
      text: "Trita Meds exclusively distributes the Dr.Matrix brand, a trusted manufacturer of high-quality hospital linens. Established in 2001 in Sangli, Maharashtra, Abhinav Distributors has become a leading name in the field. Our product range under the Dr.Matrix label includes:  Nurse Overalls, Doctors’ Overalls, Surgical Aprons, O.T. Patient Gowns, Surgical Masks, Washable & Autoclavable Products for O.T",
    },
    {
      img: "https://images.pexels.com/photos/4769133/pexels-photo-4769133.jpeg?auto=compress&cs=tinysrgb&w=800",
      title: "Custom Drapes & Packs",
      text: "We also offer customized drapes and packs tailored to the specific requirements of hospitals and individual surgeons, ensuring flexibility and precision in every procedure.",
    },
  ];

  const [loadMore, setLoadMore] = useState(false);
  const [loadMoreText, setLoadMoreText] = useState("Load More");
  const loadMoreThreshold = 2;
  const toggleLoadMore = () => {
    setLoadMore(!loadMore);
    setLoadMoreText(loadMore ? "Load More" : "Load Less");
  };

  return (
    <section className="container container__portfolio" id="portfolio">
      {/* <div className="portfolio__header">
        <h1>See Our Works Or Portfolio</h1>
        <button className="btn btn-primary">More About</button>
      </div> */}
      {works.map((work, index) => {
        if (index == 1) {
          return (
            <div key={index}>
              <div className="portfolio__header">
                <h1>Explore Drapes and Packs</h1>
                <button className="btn btn-primary" onClick={toggleLoadMore}>
                  {loadMoreText}
                </button>
              </div>
              <Work img={work.img} title={work.title} text={work.text} />
            </div>
          );
        }
        if (!loadMore && index >= loadMoreThreshold) {
          return null;
        }
        return (
          <div key={index}>
            <Work img={work.img} title={work.title} text={work.text} />;
          </div>
        );
      })}
    </section>
  );
};
/*
class Portfolio extends Component {
  constructor() {
    super();
    this.works = [
      {
        img: "https://miro.medium.com/max/1400/0*MYd28f8vpLiE34ij.jpeg",
        title: "Modern UI/UX Dashboard Design",
        text:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Est ipsam asperiores"
      },
      {
        img:
          "https://i.pinimg.com/originals/80/a1/17/80a1173fb50fe01bac507532338eb336.png",
        title: "Food and Consumation Web App",
        text:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Est ipsam asperiores"
      },
      {
        img:
          "https://i.pinimg.com/originals/56/ae/76/56ae76f7bc1b3e0edc962cea1af7035f.png",
        title: "Future Social Media Web 3.0 d'App",
        text:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Est ipsam asperiores"
      }
    ];
  }
  render() {
    return (
      <section className="container container__portfolio" id="portfolio">
        // {<div className="portfolio__header">
        //   <h1>See Our Works Or Portfolio</h1>
        //   <button className="btn btn-primary">More About</button>
        // </div> }
        {this.works.map((work, index) => {
          if (index == 1) {
            return (
              <>
                <div className="portfolio__header">
                  <h1>See Our Works Or Portfolio</h1>
                  <button className="btn btn-primary">More About</button>
                </div>
                <Work img={work.img} title={work.title} text={work.text} />
              </>
            );
          }
          return <Work img={work.img} title={work.title} text={work.text} />;
        })}
      </section>
    );
  }
}
*/
export default Portfolio;
