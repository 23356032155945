import { Component } from "react";
import Button from "../Global__Components/Button";

const Quote = () => {
  const handleGetQuote = () => {
    // scroll to the contact section
    const contact = document.querySelector("#contact");
    contact.scrollIntoView({ behavior: "smooth" });
  }
  return <Button content="Get a Quote" click={handleGetQuote}/>;
};
/*
class Quote extends Component {
  render() {
    return <Button content="Get a Quote" />;
  }
}
*/
export default Quote;
